import React from "react"
import { graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

import SEO from "../components/seo"
import Section from "../components/Section"
import { Container } from "../components/Container"
import HeroFullWidth from "../components/HeroFullWidth"
import SvgBorderTop from "../components/SvgBorderTop"
import BreadCrumbs from "../components/BreadCrumbs"
import BlockText from "../components/BlockText"
import vSpacing from "../styles/vSpacing"
import Vspacer from "../components/Vspacer"
import localeData from "../locale"
import VacancySelection from "../components/VacancySelection"
import AccordionItem from "../components/Accordion/AccordionItem"
import breakpoints from "../styles/breakpoints"
import ContactCard from "../components/ContactCard"

const Title4 = styled.h2`
  text-align: center;
  color: ${props => props.theme.primaryColors.primary};
`

const FaqBlock = styled(Grid)`
  margin-bottom: 25px;
  ${breakpoints.md`
    margin-bottom: 30px;
  `};
`

export const destinationQuery = graphql`
  query DestinationsTemplateQuery($id: String!) {
    sanityVacancyCountry(id: { eq: $id }) {
      id
      _rawLocaleTitle
      _rawTextBlock1
      _rawTextBlock2
      _rawTravelExamples
      image {
        asset {
          gatsbyImageData(width: 1600, fit: FILLMAX, placeholder: BLURRED)
        }
      }
      teamMember {
        name
        degree
        phone
        email
        image {
          asset {
            url
          }
        }
      }
    }
  }
`

const DestionationsPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const {
    _rawLocaleTitle,
    image,
    _rawTextBlock1,
    _rawTextBlock2,
    _rawTravelExamples,
    teamMember,
  } = data.sanityVacancyCountry

  return (
    <>
      <SEO title={_rawLocaleTitle[locale]} />
      {_rawLocaleTitle && (
        <BreadCrumbs
          color={image && "white"}
          pageTitle={_rawLocaleTitle[locale]}
          locale={locale}
        />
      )}
      {image && (
        <HeroFullWidth
          img={image}
          title={_rawLocaleTitle[locale]}
        />
      )}
      {_rawTextBlock1 && (
        <Section paddingTop>
          <Container>
            <Vspacer marginBottom={vSpacing.baseX3}>
              <Grid container justify="center">
                <Grid item xs={12} md={8}>
                  <BlockText blocks={_rawTextBlock1[locale]} />
                </Grid>
              </Grid>
            </Vspacer>
          </Container>
        </Section>
      )}
      {_rawTravelExamples && _rawTravelExamples.length && (
        <Section>
          <Container>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <FaqBlock item xs={12}>
                  {_rawTravelExamples.map(example => {
                    return (
                      <AccordionItem
                        key={example._key}
                        header={example.question[locale]}
                        body={example.answer}
                        locale={locale}
                      />
                    )
                  })}
                </FaqBlock>
              </Grid>
            </Grid>
          </Container>
        </Section>
      )}
      {_rawTextBlock2 && (
        <Section paddingBottom>
          <Container>
            <Vspacer marginBottom={vSpacing.baseX3}>
              <Grid container justify="center">
                <Grid item xs={12} md={8}>
                  <BlockText blocks={_rawTextBlock2[locale]} />
                </Grid>
              </Grid>
            </Vspacer>
            {teamMember && (
              <Grid container spacing={16} justify="center">
                <Grid item xs={12} md={8}>
                  <ContactCard noShadow contact={teamMember} locale={locale} />
                </Grid>
              </Grid>
            )}
          </Container>
        </Section>
      )}

      <SvgBorderTop grey>
        <Container grey paddingBottom>
          <Vspacer marginBottom={vSpacing.baseX6}>
            <Grid container justify="center">
              <Grid item md={6}>
                <Title4 center>
                  {localeData._rawDestinationVacancies[locale]}{" "}
                  {_rawLocaleTitle[locale]}
                </Title4>
              </Grid>
            </Grid>
          </Vspacer>
          <Section>
            <VacancySelection
              locale={locale}
              filter={_rawLocaleTitle[locale]}
            />
          </Section>
        </Container>
      </SvgBorderTop>
    </>
  )
}

export default DestionationsPage

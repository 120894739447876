import React from "react"
import styled from "styled-components"
import Card from "../Card"
import vSpacing from "../../styles/vSpacing"
import Typography from "../Typography"
import { StartingPeriodIcon } from "../Icons/MetaIcons"
import breakpoints from "../../styles/breakpoints"

const { H200 } = Typography

const Vacancy = styled(Card)`
  padding: ${vSpacing.baseX2};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: ${props => props.theme.transitions.hover};
  ${breakpoints.md`
    padding: ${vSpacing.baseX4};
  `};

  &:hover {
    cursor: pointer;
    box-shadow: ${props => props.theme.boxShadow.zIndex5};
    h5 {
      color: ${props => props.theme.primaryColors.primary};
    }
  }
`

const Category = styled.div`
  ${H200}
  color: ${props => props.theme.primaryColors.primary};
  padding-bottom: ${vSpacing.base};
  font-weight: 600;
`
const CategoryItem = styled.div`
  display: flex;
`

const Title = styled.h5`
  color: ${props => props.theme.colors.black900};
  padding-bottom: ${vSpacing.baseX2};
  font-weight: 600;
  transition: ${props => props.theme.transitions.hover};
  ${breakpoints.md`
    padding-bottom: ${vSpacing.baseX7};
    font-size: 20px;
  `};
`

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${vSpacing.base} 0;
`

const VacancyCard = ({
  title,
  type,
  flag,
  country,
  category,
  startingPeriod,
  locale,
}) => (
  <Vacancy>
    <div className="vacancy-top">
      <Category>{type}</Category>
      <Title>{title}</Title>
    </div>
    <div className="vacancy-bottom">
      {country && (
        <Detail>
          {flag && (
            <img
              src={`${flag.asset.url}?w=24`}
              style={{
                marginRight: "12px",
                maxWidth: "24px",
              }}
              alt={country || "Country"}
            />
          )}
          <p>{country}</p>
        </Detail>
      )}
      {category && (
        <Detail>
          <CategoryItem key={category.id}>
            {category.categoryIcon && (
              <img
                src={`${category.categoryIcon.asset.url}?w=24`}
                style={{
                  marginRight: "12px",
                  maxWidth: "24px",
                }}
                alt={category._rawLocaleTitle[locale] || "Category"}
              />
            )}
            {category._rawLocaleTitle && (
              <p>{category._rawLocaleTitle[locale]}</p>
            )}
          </CategoryItem>
        </Detail>
      )}
      <Detail>
        {startingPeriod && (
          <>
            <StartingPeriodIcon margin="0 12px 0 0" />
            <p>{startingPeriod}</p>
          </>
        )}
      </Detail>
    </div>
  </Vacancy>
)

export default VacancyCard

import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Card from "../Card"
import vSpacing from "../../styles/vSpacing"
import Typography from "../Typography"
import { WhatsAppIcon } from "../Icons/index"
import { ExternalLinkUnderline } from "../NormalLink"
import breakpoints from "../../styles/breakpoints"
import localeData from "../../locale"

const { H400Semibold, H400 } = Typography

const Contact = styled(Card)`
  padding: ${vSpacing.baseX2};
  height: 100%;
  width: 100%;
  box-shadow: ${props => props.noShadow ? 'unset' : props.theme.boxShadow.zIndex1};
  ${breakpoints.md`
    padding: ${vSpacing.baseX5} 0;
  `};
`

const Contactimage = styled.img`
  border-radius: 50%;
  height: 64px;
  width: 64px;
  margin-bottom: ${vSpacing.baseX2};
  ${breakpoints.md`
    margin: 0 auto;
    height: 96px;
    width: 96px;  
  `};
  ${breakpoints.lg`
    margin: 0 auto;
    height: 112px;
    width: 112px;  
  `};
`

const ContactName = styled.div`
  ${H400Semibold}
  color: ${props => props.theme.colors.black900};
  ${breakpoints.md`
    font-size: 20px;
  `};
`

const ConctactJob = styled.div`
  ${H400}
  margin-bottom: ${vSpacing.baseX2};
  ${breakpoints.md`
    margin-bottom: ${vSpacing.baseX3};
    font-size: 20px;
  `};
`

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
`

const ContactInfoMobileDivider = styled.div`
  margin-bottom: ${vSpacing.baseX2};
  width: 100%;
  ${breakpoints.lg`
    display: none;
  `};
`

const ContactCard = ({ contact, locale, noShadow }) => (
  <Contact noShadow={noShadow}>
    <Grid container alignItems="center">
      <Grid container item xs={12} md={4}>
        <Contactimage
          alt={contact.name}
          src={`${contact.image.asset.url}?w=142`}
        />
      </Grid>
      <Grid item md={8}>
        {contact.name && <ContactName>{contact.name}</ContactName>}
        {contact.degree && <ConctactJob>{contact.degree}</ConctactJob>}
        <ContactInfo>
          <Grid container>
            <Grid container item xs={12} md={12} lg={6}>
              {contact.phone && (
                <>
                  <Grid item md={12}>
                    <strong>{localeData._rawPhone[locale]}</strong>
                  </Grid>
                  <Grid item md={12} container alignItems="center">
                    <WhatsAppIcon width="24" height="24" margin="0 8px 0 0" />
                    <ExternalLinkUnderline href={`tel:&{contact.phone}`}>
                      {contact.phone}
                    </ExternalLinkUnderline>
                  </Grid>
                </>
              )}
            </Grid>
            {contact.email && (
              <>
                <ContactInfoMobileDivider />
                <Grid item xs={12} md={12} lg={6}>
                  <Grid item md={12}>
                    <strong>{localeData._rawMail[locale]}</strong>
                  </Grid>
                  <Grid item md={12} container alignItems="center">
                    <ExternalLinkUnderline href={`mailto:${contact.email}`}>
                      {contact.email}
                    </ExternalLinkUnderline>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </ContactInfo>
      </Grid>
    </Grid>
  </Contact>
)

export default ContactCard
